import React, { useContext } from 'react'
import { Button, Card } from 'react-bootstrap'
import { ArrowRightShort, } from 'react-bootstrap-icons'
import { LinkContainer } from 'react-router-bootstrap';
import { LangContext } from '../../context/LangContextProvider';

interface Props{
    icon: any;
    title: string;
    subtitle: string;
    buttonTitle: string; 
    pageTitle: string;
}

const InfoCard = ({title, subtitle, buttonTitle, icon, pageTitle}:Props) => {
    const lang = useContext(LangContext);
    return (
        <Card className='card'>
        <Card.Body className='card-body'>
          <div className='card-icon'>{icon}</div>
          <Card.Title className='card-title'>{title}</Card.Title>
          <Card.Text className='card-text'>{subtitle}</Card.Text>
          <LinkContainer to={`/${lang}/${pageTitle}`}>
          <Button variant='dark' className='card-btn'>{buttonTitle} <ArrowRightShort size={16} /></Button>
          </LinkContainer>
      
        </Card.Body>
      </Card>
    )
}

export default InfoCard