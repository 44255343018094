import React from 'react'
import { Container } from 'react-bootstrap'
import { Route, Routes } from 'react-router-dom'
import About from './About'
import CloudExample from './CloudExample'
import Contacts from './Contacts'
import CustomExample from './CustomExample'
import Index from './Index'

const Pages = () => {
    return (
        <Container >
            <Routes>
                <Route path='/' element={<Index />} />
                <Route path='/About' element={<About />}/>
                <Route path='/Contacts' element={<Contacts />}/>
                <Route path='/CustomExample' element={<CustomExample/>}/>
                <Route path='/CloudExample' element={<CloudExample/>}/>
            </Routes>
        </Container>
    )
}

export default Pages