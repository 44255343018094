import React, { useContext } from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { useLocation } from 'react-router-dom';
import { LangContext, LangType } from '../context/LangContextProvider';

interface ResourceStructure {
    home: string
    about: string
    contact: string
}

const resources: { [index in LangType]: ResourceStructure } = {
    fr: {
        home: "Accueil",
        about: "À propos",
        contact: "Contacts"
    },
    en: {
        home: "Home",
        about: "About",
        contact: "Contact"
    }
}

const Header = () => {

    const lang = useContext(LangContext);

    const { pathname } = useLocation();

    const localizedResources = resources[lang]

    return (
        <Navbar className="navbar" bg="light" expand="lg">
            <Container>
                <LinkContainer to={`/${lang}`}>
                    <Navbar.Brand><img src='https://sgalincpublicstorage.blob.core.windows.net/common/logos/sgal-cropped.png' className='navbar-logo' alt='Logo SGAL'></img></Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav className="me-auto">
                        <LinkContainer to={`/${lang}`}>
                            <Nav.Link className="navlink">{localizedResources.home}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${lang}/About`}>
                            <Nav.Link className='navlink' >{localizedResources.about}</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/${lang}/Contacts`}>
                            <Nav.Link className='navlink' >{localizedResources.contact}</Nav.Link>
                        </LinkContainer>
                    </Nav>
                    <Nav>
                        <Nav.Item>
                            <LinkContainer to={lang === 'fr' ? `/en${pathname.substring(3)}` : `/fr${pathname.substring(3)}`}>
                                <Nav.Link>{lang === 'fr' ? "EN" : "FR"}</Nav.Link>
                            </LinkContainer>
                        </Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header