
import React, { useContext } from 'react'
import { LangContext, LangType } from '../context/LangContextProvider'


interface ResourceStructure {
  question: string,
  response: string,
  challenge: string,
}

const resources: { [index in LangType]: ResourceStructure } = {
  fr: {
    question: "Vous avez un problème d'affaires?",
    response: "Nous sommes là pour vous!",
    challenge: "Mettez-nous au défi, vous comprendrez alors toute la puissance de notre créativité et des solutions novatrices que nous proposons! Si la solution n'existe pas, nous la créerons!"
  },
  en: {
    question: "You have a business challenge?",
    response: "We are there to help!",
    challenge: "Challenge us, you will then see why we are leaders and the innovative solutions we can come up with! If the solution does not exist, we will create it!"
  }
}
const About = () => {
  const lang = useContext(LangContext);
  const localizedResources = resources[lang]
  return (
    <>
      <div className="about-hero">
        <img className='about-img' src="/assets/aboutImages/shapesSGAL.png" alt="Shapes" />
        <div className='about'>
          <div className='about-title fade-in-text'>{localizedResources.question}</div>
          <div className='about-subtitle'>{localizedResources.response}</div>
          <div className='about-info-text'>{localizedResources.challenge}</div>
        </div>
      </div>
    </>
  )
}

export default About