import React, { useContext } from 'react'
import { LangContext, LangType } from '../context/LangContextProvider';

interface ResourceStructure {
    footerText: string,
  }
  
  const resources: { [index in LangType]: ResourceStructure } = {
    fr: {
      footerText: `© ${new Date().getFullYear()} - Systèmes de gestion Alain Legault inc.`,
    },
    en: {
      footerText: `© ${new Date().getFullYear()} - Systèmes de gestion Alain Legault inc.`,
    }
}

const Footer = () => {
    const lang = useContext(LangContext);
    const localizedResources = resources[lang]
    return (
        <>
            <div className="container">
                <div className='footer'>
                    <div className='separator'></div>

                    <div className='footer-text'>
                        {localizedResources.footerText}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer