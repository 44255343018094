import React from 'react'
import { Button } from 'react-bootstrap'
import { Envelope } from 'react-bootstrap-icons'

interface Props {
    title: string;
    buttonText: string;
}

const ContactUsButton = ({ title, buttonText }: Props) => {
    return (
        <section className='custom-contact'>
            <div className="custom-contact-title">{title}</div>
            <Button variant='dark' ><a className='custom-contact-btn' href="mailto:info@sgal.app">{buttonText.toUpperCase()}<Envelope size={20} /></a> </Button>
        </section>
    )
}

export default ContactUsButton