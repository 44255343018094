import React, { useContext } from 'react'
import {  GeoAltFill,  PersonFill, TelephoneFill } from 'react-bootstrap-icons'
import { LangContext, LangType } from '../context/LangContextProvider';


interface ResourceStructure {
  contact: string,
  quebec: string,
}
const resources: { [index in LangType]: ResourceStructure } = {
  fr: {
    contact: "Contactez-nous",
    quebec: "Québec",
  },
  en: {
    contact: "Contact Us",
    quebec: "Quebec",
  }
}

const Contacts = () => {
  const lang = useContext(LangContext);
  const localizedResources = resources[lang]
  return (
    <div className='contact'>
      
      <div className='contact-title'>{localizedResources.contact}</div>
      <div className='contact-info'>
        <div className="contact-info-item">
          <PersonFill className='item-icon' color='white' />
          <div className='item-text'><a href="mailto:info@sgal.app">SGAL inc.</a></div>
        </div>
        <div className="contact-info-item">
          <a href='https://maps.app.goo.gl/pXdU398KzYW5ncEV9'><GeoAltFill className='item-icon item-location' color='white' /></a>
          <div className='location'>
            <div className='item-text'>449, Chemin des Pionniers Ouest</div>
            <div className='item-text'>Cap-Saint-Ignace ({localizedResources.quebec}) G0R 1H0</div>
          </div>
        </div>
        <div className="contact-info-item">
          <TelephoneFill className='item-icon' color='white' />
          <div>
          <div className='item-text'>Qc: (418) 291-2448</div>
          <div className='item-text'>Mtl: (514) 710-3747</div>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Contacts
