import React, { useContext } from 'react'
import { GearFill } from 'react-bootstrap-icons'
import { LangContext, LangType } from '../context/LangContextProvider';
import ContactUsButton from '../layouts/ContactUsButton';


interface ResourceStructure {
  title: string,
  process: string,
  yearlyEvaluation: string,
  communication: string,
  onlineCoursesManagement: string,
  onlineCoursesCreation: string,
  healthSafety: string,
  golf: string,
  charityEvent: string,
  msAccess: string,
  foreignWorkers: string,
  lottery: string,
  dataTracking: string,
  help: string,
  contact: string,
  vacancier: string,

}
const resources: { [index in LangType]: ResourceStructure } = {
  fr: {
    title: "Voici quelques exemples de réalisation sur mesure",
    process: "Gestion du flot de processus de création d'activités jusqu'au paiement",
    yearlyEvaluation: "Gestion du questionnaire d'évaluation de la performance au travail",
    communication: "Gestion d'un questionnaire de préférences communicatives",
    onlineCoursesManagement: "Plateforme de gestion de formation en ligne",
    onlineCoursesCreation: "Formations en ligne",
    healthSafety: "Gestion de la santé et sécurité au travail",
    golf: "Gestion de tournoi de golf (avec paiement en ligne et commandites)",
    charityEvent: "Gestion d'événements caritatifs",
    msAccess: "Gestion de bases de données et applications MS-Access",
    foreignWorkers: "Gestion de permis de travail des employés étrangers",
    lottery: "Gestion de billets de tirage pour campagnes de financement",
    dataTracking: "Gestion des données environnementales pour émission de rapports",
    vacancier: "Gestion des vacances annuelles par ancienneté",
    help: "Nous pouvons vous aider",
    contact: "Contactez-nous",
  },
  en: {
    title: "Here are a few examples of custom made solutions",
    process: "Workflow management from activity creation to its payment",
    yearlyEvaluation: "Yearly Evaluation of Employees Management",
    communication: "Communication Preferences Questionnaire Management",
    onlineCoursesManagement: "Online Courses Management System",
    onlineCoursesCreation: "Online Courses creation",
    healthSafety: "Health & Safety Management at work",
    golf: "Golf Tournament Management (with online payment and Donors)",
    charityEvent: "Charity Event Management Solution",
    msAccess: "MS-Access application development and database management",
    foreignWorkers: "Foreign Workers Work Permit Management",
    lottery: "Lottery Ticket Management for Fundraising Campaigns",
    dataTracking: "Environment data tracking to produce various reports",
    vacancier: "Yearly Vacation Scheduler by Seniority",
    help: "We can help you",
    contact: "Contact us",
  }
}


const CustomExample = () => {
  const lang = useContext(LangContext);
  const localizedResources = resources[lang]
  return (
    <>
      <div>
        <div className='custom-hero'>
          <div className='custom-title'>{localizedResources.title}</div>
          <div className='separator-sm'></div>
        </div>
        <section className="solutions">
          <div className='custom-solution'>
            <img className='solution-image' src="/assets/customImages/stock1.jpg" alt="" />
            <div className="solution-info">
              <div className='custom-info-subtitle'></div>
              <ul className='custom-info-list'>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.charityEvent}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.golf}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.foreignWorkers}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.lottery}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.onlineCoursesManagement}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.onlineCoursesCreation}</li>
              </ul>
            </div>
          </div>
          <div className='custom-solution'>
            <div className="solution-info">
              <div className='custom-info-subtitle'> </div>
              <ul className='custom-info-list'>
                <li className='custom-info-text'> <GearFill color='#2A5B9A' size={15} />{localizedResources.dataTracking}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.communication}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.process}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.yearlyEvaluation}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.healthSafety}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.msAccess}</li>
                <li className='custom-info-text'><GearFill color='#2A5B9A' size={15} />{localizedResources.vacancier}</li>
              </ul>
            </div>
            <img className='solution-image' src="/assets/customImages/stock2.jpg" alt="Copyright: <a href='https://fr.123rf.com/profile_buchachon'>buchachon</a>" />
          </div>
        </section>
        <ContactUsButton title={localizedResources.help} buttonText={localizedResources.contact} />
      </div>
    </>
  )
}

export default CustomExample