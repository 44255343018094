import React, { useContext } from 'react'
import { Button } from 'react-bootstrap';
import { BagCheckFill, CloudFill, CloudSun, Envelope, LockFill, PersonBadgeFill, PersonLinesFill } from 'react-bootstrap-icons'
import { LangContext, LangType } from '../context/LangContextProvider';
import Solution from '../layouts/CloudExample/Solution';


interface ResourceStructure {
  title: string,
  diligent: string,
  diligentText: string,
  lock: string,
  lockText: string,
  employer: string,
  employerText: string,
  appraiser: string,
  appraiserText: string,
  vacationer:string,
  vacationerText: string,
  help: string,
  contact: string,
}
const resources: { [index in LangType]: ResourceStructure } = {
  fr: {
    title: "Voici des solutions hébergées, développées pour aider l'entreprise d'ici à performer sans se compliquer la tâche",
    diligent: "Le Diligent",
    diligentText: "Le Diligent est LA solution qui vous permet de rencontrer les exigences de la diligence raisonable en gestion de la santé sécurité. En effet, les trois devoirs (Prévoyance, Efficacité, Autorité) sont rencontrés grâce à cet outil convivial.",
    lock: "Le Cadenasseur",
    lockText: "Le Cadenasseur vous permet d'identifier vos équipements et de créer des fiches de cadenassage répondant aux plus hauts standards. Ces fiches pourrront être créées, consultées, modifiées et validées via une interface simple d'utilisation.",
    employer: "L'Employeur",
    employerText: "L'Employeur vous permet de gérer les différents types de permis de travail, les certificats de sélection (Qc seulement), les offres d'emplois et Études d'Impact du Marché du Travail (EIMT). Soyez avisés lorsque vous devez renouveler votre EIMT ou encore mentionner à l'employé qu'il doive faire sa demande de renouvellement de permis de travail... Suivez-le jusqu'à l'obtention de sa résidence permanente!",
    appraiser: "L'Évaluateur",
    appraiserText: "L'Évaluateur vous permet de définir des objectifs annuels, des comportements et des engagements et de les suivre lors d'évaluation semi-annuelle et annuelle. Demandez du feedback à vos pairs pour justifier votre évaluation!",
    vacationer: "Le Vacancier",
    vacationerText:"Le Vacancier vous permet d'importer votre structure organisationnelle et d'y associer les vacances de vos employés selon vos paramètres et contraintes opérationnelles.",
    help: "Nous pouvons vous aider!",
    contact: "Contactez-nous"
  },
  en: {
    title: "Here are hosted solutions designed to help entreprises in performing, without taking away valuable time",
    diligent: "The Diligent",
    diligentText: "The Diligent is THE solution that allows your entreprise to meet Health & Safety Due Diligence Management.",
    lock: "The Lock",
    lockText: "The Lock gives you access to create, manage, print Lockout/Tagout files through a simple and intuitive interface.",
    employer: "The Employer",
    employerText: "The Employer allows you to manage the different types of Foreign Workers Work Permits. Track the Job offers and LMIA expiration dates, advise your employees with Open Permits of the renewal of their permits, track them until they get their Permanent Resident status!",
    appraiser: "The Appraiser",
    appraiserText: "The Appraiser lets you conduct semi-yearly and yearly job performance reviews, as well as getting feedback from your co-workers!",
    vacationer: "The Vacationer",
    vacationerText:"The Vacationer lets you import your company structure and define vacation schedules based on your own constraints and let your employees chose their vacations by seniority ",
    help: "We can help you!",
    contact: "Contact us"
  }
}


const CloudExample = () => {
  const lang = useContext(LangContext);
  const localizedResources = resources[lang]
  return (
    <>
      <section className='cloud-hero'>
        <CloudFill size={150} color="#3a3a3a" />
        <div className="cloud-hero-title">{localizedResources.title.toUpperCase()}</div>
      </section>
      <section className='cloud-solutions'>
          <Solution title={localizedResources.diligent} icon={<PersonBadgeFill size={65} />} text={localizedResources.diligentText} />
          <Solution title={localizedResources.lock} icon={<LockFill size={65} />} text={localizedResources.lockText} />
          <Solution title={localizedResources.employer} icon={<PersonLinesFill size={65} />} text={localizedResources.employerText} />
          <Solution title={localizedResources.appraiser} icon={<BagCheckFill size={65} />} text={localizedResources.appraiserText} />
          <Solution title={localizedResources.vacationer} icon={<CloudSun size={65} />} text={localizedResources.vacationerText} />
      </section>
      <section className='custom-contact'>
          <div className="custom-contact-title">{localizedResources.help}</div>
          <Button variant='dark' ><a className='custom-contact-btn' href="mailto:info@sgal.app">{localizedResources.contact.toUpperCase()}<Envelope size={20} /></a> </Button>
        </section>
    </>
  )
}

export default CloudExample