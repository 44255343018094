import React from 'react'

interface Props{
    icon: any;
    title: string;
    text: string;
}

const Solution = ({title,  icon, text}:Props) => {
    return (
        <div className="cloud-solution">
          {icon}
          <h1 className='solution-title'>{title}</h1>
          <p className='solution-text'>{text}</p>
        </div>
    )
}

export default Solution