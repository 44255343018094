import React from 'react';
import Header from './layouts/Header';
import Pages from './pages/Pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import './layouts/style.css';
import Footer from './layouts/Footer';

const App = () => {

  return (
    <>
      <Header />
      <Pages/>
      <Footer/>
    </>
  );
}

export default App;
