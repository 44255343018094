import React, { useContext } from 'react'
import { Building, Cloud } from 'react-bootstrap-icons'
import { LangContext, LangType } from '../context/LangContextProvider'
import InfoCard from '../layouts/Index/InfoCard'

interface ResourceStructure {
  title: string,
  subtitle: string,
  
  cardTitle1: string,
  cardSubtitle1: string,
  cardButtonTitle1: string,
  cardTitle2: string,
  cardSubtitle2: string,
  cardButtonTitle2: string,
  
}

const resources: { [index in LangType]: ResourceStructure } = {
  fr: {
    title: "Systèmes de gestion Alain Legault inc.",
    subtitle: "Transformez VOS besoins en SOLUTIONS",
    
    cardTitle1: "Des solutions bâties sur mesure",
    cardSubtitle1: "La force de notre entreprise est de transformer VOS besoins d'affaires en solutions pratiques et simples. Cliquez sur le bouton afin d'en apprendre davantage.",
    cardButtonTitle1: "EN SAVOIR PLUS",
    cardTitle2: "Des solutions hébergées pour les entreprises",
    cardSubtitle2: "Certains clients préfèrent les solutions déjà bâties et hébergées, nous avons développé quelques outils pour la gestion de la diligence en santé et sécurité ainsi que la gestion des permis de travail des employés étrangers",
    cardButtonTitle2: "EN SAVOIR PLUS",
    
  },
  en: {
    title: "Systèmes de gestion Alain Legault inc.",
    subtitle: "Transform YOUR business needs into SOLUTIONS",
    
    cardTitle1: "Custom Made Solutions",
    cardSubtitle1: "Our business strength is to start from your entreprise's needs and deliver a simple and practical solution. Click to see some examples!",
    cardButtonTitle1: "LEARN MORE",
    cardTitle2: "Cloud-Based Entreprise Solutions",
    cardSubtitle2: "Some customers prefer to use our cloud-based solutions. This is why we offer some tools to manage the Health & Safety due diligence as well as managing the Foreign workers work permits",
    cardButtonTitle2: "KNOW MORE",
    
  }
}

const Index = () => {
  const lang = useContext(LangContext);
  const localizedResources = resources[lang]
  return (
    <>
      <section className='hero'>
        <div className='hero-title'>
          <div className='hero-title-text'>
            <h1 className='title'>{localizedResources.title}</h1>
            <h2 className='subtitle'>{localizedResources.subtitle}</h2>
            
          </div>
          <img className='hero-image' src="/assets/homeImages/SGAL2.png" alt="sgal" />
        </div>
      </section>
      <section className='info'>
        <InfoCard title={localizedResources.cardTitle1} subtitle={localizedResources.cardSubtitle1} buttonTitle={localizedResources.cardButtonTitle1} icon={<Building size={50} color='#2A5B9A'></Building>} pageTitle="CustomExample" />
        <InfoCard title={localizedResources.cardTitle2} subtitle={localizedResources.cardSubtitle2} buttonTitle={localizedResources.cardButtonTitle2} icon={<Cloud size={50} color='#2A5B9A'></Cloud>} pageTitle="CloudExample" />
      </section>
    </>
  )
}
export default Index