import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LangContext } from './context/LangContextProvider';
import Favicon from 'react-favicon';
import ScrollToTop from './layouts/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  
  <BrowserRouter>
  <Favicon url='https://sgalincpublicstorage.blob.core.windows.net/common/favicon/favicon64.ico' />
    <React.StrictMode>
      <ScrollToTop/>
      <Routes>
        <Route path="/en/*" element={<LangContext.Provider value='en'><App /></LangContext.Provider>}/>
        <Route path="/fr/*" element={<LangContext.Provider value='fr'><App /></LangContext.Provider>}/>
        <Route
        path="*"
        element={<Navigate to="/fr" replace />}
    />
      </Routes>
      {/* <App /> */}
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
